import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { WixGroupsController } from 'controllers/controller';
import { Resolvables, waitForTransition } from 'router/helpers';

import { setupRouter } from '../router';

export const createController: CreateControllerFn = async (params) => {
  const { isSSR } = params.flowAPI.environment;
  const controller = new WixGroupsController(params);

  controller.handleTransition = handleTransition;

  setupRouter(params, controller.vm);

  controller.router.urlService.rules.initial({ state: 'groups' });
  controller.router.urlService.rules.otherwise({ state: 'groups' });

  return controller;

  async function handleTransition(resolvables?: Resolvables) {
    return waitForTransition(controller.router, {
      resolvables,
      to: 'groups',
      disableWaitForResolvables: !isSSR,
      blacklist: ['comments', 'commentsApi'],
    });
  }
};
