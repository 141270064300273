import { ControllerParams } from '@wix/yoshi-flow-editor';

import * as groups from 'store/groups';

import { getAppData } from './helpers';

export function GroupsController(params: ControllerParams) {
  const { store } = getAppData(params);

  return {
    groups$: {
      queryJoined,
    },
  };

  function queryJoined(query?: string, limit = 10, offset = 0) {
    return store.dispatch(
      groups.thunks.queryJoined({
        query,
        limit,
        offset,
      }),
    );
  }
}

export type IGroupsController = ReturnType<typeof GroupsController>;
